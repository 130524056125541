<template>
  <div @click="goToMenu" id="go-back-button">
    <font-awesome-icon id="go-back-icon" icon="arrow-left"></font-awesome-icon>
  </div>
</template>

<script>
export default {
  name: "GoBackButton",
  computed: {
    tableId() {
      return this.$store.getters.getTableId;
    },
  },
  methods: {
    goToMenu: function () {
      this.$emit("fluter-back-pressed");

      let queryObject = null;
      if (this.$route.query && this.$route.query.isPda != undefined && this.$route.query.pdaId != undefined && this.$route.query.tableId != undefined) {
        queryObject = { isPda: this.$route.query.isPda, pdaId: this.$route.query.pdaId, tableId: this.$route.query.tableId };
        if (this.$route.query.tableOpeningId != undefined) {
          queryObject.tableOpeningId = this.$route.query.tableOpeningId;
        }
        if (this.$route.query.pdaUserId != undefined) {
          queryObject.pdaUserId = this.$route.query.pdaUserId;
        }
        if (this.$route.query.fromOpenTable != undefined && (this.$route.query.fromOpenTable == true || this.$route.query.fromOpenTable == 'true')) {
          queryObject.fromOpenTable = this.$route.query.fromOpenTable;
        }
      } else if (this.$route.query && this.$route.query.backTo != undefined) {
        queryObject = { backTo: this.$route.query.backTo }
      }

      if (this.$route.query && this.$route.query.originTableId != undefined) {
        if (queryObject == null) {
          queryObject = { originTableId: this.$route.query.originTableId };
        } else {
          queryObject.originTableId = this.$route.query.originTableId;
        }
      }

      if (this.$route.params.menu && this.$route.params.store) {
        let storeWithMenus = {
          store: this.$route.params.store,
          menus: [this.$route.params.menu],
        };
        this.$router.push({ name: "menu", params: { tableId: this.tableId, menu: storeWithMenus }, query: queryObject });
      } else {
        this.$router.push({ name: "menu", params: { tableId: this.tableId }, query: queryObject });
      }
    },
  },
};
</script>

<style scoped>
#go-back-button {
  width: 43px;
  height: 43px;
  background: white;
  border-radius: 16px;
  display: flex;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer !important;
  z-index: 201;
}
@media screen and (max-width: 1018px) {
  #go-back-button {
    width: 42px;
    height: 42px;
  }
}

#go-back-icon {
  font-size: 16px;
  color: #003340;
  margin: 0;
}
@media screen and (max-width: 1018px) {
  #go-back-icon {
    font-size: 16px;
    width: 18px;
    height: 18px;
  }
}
</style>